export default {
  transportation: 'transportation',
  transportationInActive: 'Inactive drivers',
  companyInActive: 'Inactive compaines',
  driverRequest: 'Driver activation request',
  companyRequest: 'Company activation request',
  process: 'process',
  companies: 'companies',
  company: 'company',
  drivers: 'drivers',
  driver: 'driver',
  vehiclesType: 'vehicles Type',
  vehiclesMake: 'vehicles Make',
  vehicles: 'vehicles',
  companySettings: 'company settings',
  driversSettings: 'drivers settings',
  driver_suspension_reason: 'Driver Suspension Reason',
  company_suspension_reason: 'Company Suspension Reason',
  personal_data: 'Personal Data',
  full_name: 'Full Name',
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  id_number: 'Id Number',
  address: 'Address',
  city: 'City',
  description: 'Description',
  status: 'Status',
  created_at: 'Created At',
  logo: 'Logo',
  cover: 'Cover',
  view_image: 'View Image',
  photo: 'photo',
  photos: 'photos'
}
